<template>
  <div v-loading="filterLoading" v-if="showQuickFilters">
    <div v-if="showVertically">
      <el-collapse>
        <el-collapse-item v-for="(field, i) in filterFields" :key="i + 'field'">
          <template slot="title">
            <span class="collopse-header">{{ getFieldLabel(field) }}</span>
          </template>
          <div>
            <QuickEntityFilter
              :entityId="getSelectedEntityId(field)"
              :fieldPlaceholder="`By ${getFieldLabel(field)}`"
              :selectedFileds="selectedFileds"
              :filter="field"
              :fromCD="fromCD"
              v-if="checkIsEntityFilter(field)"
              @entityFilterUpdated="filterUpdated"
            >
            </QuickEntityFilter>
            <el-checkbox-group
              @change="filterUpdated"
              v-model="selectedFileds[field]"
              style="padding: 5px; margin-left: 10px"
              v-else
            >
              <el-checkbox
                v-for="(op, index) in getFieldOptions(field)"
                :key="index + op"
                :label="getLabel(op, 'value')"
              >
                {{ getLabel(op, "label") }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div style="display: flex" v-else>
      <div
        :style="
          fromCD
            ? 'display: inline-block; max-width:150px;'
            : 'display: inline-block;'
        "
        v-for="(field, i) in filterFields"
        :key="i + field"
        class="mr-1"
      >
        <div v-if="checkType(field)">
          <div
            v-if="
              checkType(field) === 'DATE' || checkType(field) == 'DATE_TIME'
            "
            class="date-filter-container"
          >
            <QuickEntityFilter
              :entityId="getSelectedEntityId(field)"
              :fieldPlaceholder="`By ${getFieldLabel(field)}`"
              :selectedFileds="selectedFileds"
              :filter="field"
              :fromCD="fromCD"
              v-if="checkIsEntityFilter(field)"
              @entityFilterUpdated="filterUpdated"
            >
            </QuickEntityFilter>
            <el-select
              v-else
              v-model="selectedFileds[field]"
              @change="filterUpdated"
              clearable
              class="mr-1 border-select"
              :placeholder="`By ${getFieldLabel(field)}`"
              size="mini"
              style="width: 100%"
            >
              <el-option
                v-for="(op, index) in pickerOptions"
                :key="index + op"
                :label="op.label"
                :value="op.value"
              >
                {{ op.label }}
              </el-option>
            </el-select>
            <div class="date-picker-container">
              <el-date-picker
                v-if="selectedFileds[field] == 'custom_date'"
                v-model="dateFilter.custom_date"
                size="mini"
                style="width: 100%"
                placeholder="Select custom data"
                @change="applyCustomDateFilter(dateFilter.custom_date, field)"
                format="MM-dd-yyyy"
              ></el-date-picker>
              <el-date-picker
                v-if="selectedFileds[field] == 'custom_date_range'"
                v-model="dateFilter.custom_date_range"
                size="mini"
                style="width: 100%"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                type="daterange"
                @change="
                  applyCustomDateRangeFilter(
                    dateFilter.custom_date_range,
                    field
                  )
                "
                format="MM-dd-yyyy"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- <div v-else-if="checkType(field) === 'DATE_TIME'">
        {{selectedFileds[field]}}
       </div> -->
          <div v-else>
            <QuickEntityFilter
              :entityId="getSelectedEntityId(field)"
              :fieldPlaceholder="`By ${getFieldLabel(field)}`"
              :selectedFileds="selectedFileds"
              :filter="field"
              :fromCD="fromCD"
              v-if="checkIsEntityFilter(field)"
              @entityFilterUpdated="filterUpdated"
            >
            </QuickEntityFilter>
            <div v-else>
              <el-tooltip
                :content="`By ${getFieldLabel(field)}`"
                placement="top-start"
              >
                <el-select
                  v-model="selectedFileds[field]"
                  @change="filterUpdated"
                  multiple
                  collapse-tags
                  class="mr-1 border-select"
                  :placeholder="`By ${getFieldLabel(field)}`"
                  size="mini"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(op, index) in getFieldOptions(field)"
                    :key="index + op"
                    :label="getLabel(op, 'label')"
                    :value="getLabel(op, 'value')"
                  >
                    {{ getLabel(op, "label") }}
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <el-link
        :underline="false"
        type="primary"
        v-if="filterFields.length > 1 && checkOneFilter"
        @click="clearFilters"
        >Reset</el-link
      >
    </div>
    <!-- </el-col>
    </el-row> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import AutomationHelper from "@/mixins/AutomationHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import moment from "moment";

export default {
  mixins: [
    CustomDashboardConfig,
    AutomationHelper,
    EntityHelper,
    templateBuilderHelper,
  ],
  data() {
    return {
      filterLoading: false,
      entityVariableSelectFields: {},
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
        {
          label: "Custom date",
          value: "custom_date",
        },
        {
          label: "Custom date range",
          value: "custom_date_range",
        },
      ],
      dateFilter: {
        filter_field: "",
        selected_date_picker: "",
        entity_id: "",
        custom_date: "",
        custom_date_range: [],
      },
      relationFilterEntityFields: [],
      relationFilterEntity: null,
      showQuickFilters: false,
      relatedFilterEntityRelationships : []
    };
  },
  components: {
    QuickEntityFilter: () => import("./quickEntityFieldFilter.vue"),
  },
  computed: {
    checkOneFilter() {
      let selectedData = 0,
        selctedFilter = false;
      this.filterFields.forEach((el) => {
        if (this.selectedFileds[el] && this.selectedFileds[el].length) {
          selectedData++;
        }
        if (this.selectedFileds[el] && this.selectedFileds[el].length > 1) {
          selctedFilter = true;
        }
      });
      return selectedData > 1 || selctedFilter ? true : false;
    },
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entities", ["getChildEntityDataById"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
  },
  watch: {
    // $route() {
    //   this.selectedFileds = {};
    // },
  },
  async mounted() {
    // this.filterFields.forEach((e) => this.$set(this.selectedFileds, e, []));
    this.showQuickFilters = false;
    await Promise.all([
      this.checkRelationEntityFilters(),
      this.checkEntityVariablesFields(),
    ]);
    this.showQuickFilters = true;
  },
  methods: {
    async checkRelationEntityFilters() {
      let relationFilterFields = this.filterFields.filter(
        (fld) => fld.split("#").length == 3
      );
      if (relationFilterFields.length) {
        await this.$store.dispatch("entities/fetchChildEntityById",relationFilterFields[0].split("#")[2]);
        if (this.getChildEntityDataById && this.getChildEntityDataById.templates) {
          await Promise.all(
            this.getChildEntityDataById.templates.map((e) => {
              if (e && e.templateInfo && !e.is_repeatable) {
                this.relationFilterEntityFields = [
                  ...this.relationFilterEntityFields,
                  ...this.applyCustomizationOnFields(
                    this.getTemplateFields(e.templateInfo, true, true),
                    e.customization,
                    e
                  ).map((f) => {
                    if (f?.key && !f.key.includes("#")) {
                      f.key = f.template_id + "#" + f.key;
                    }
                    return f;
                  }),
                ];
              }
            })
          );
          await this.setRelationshipFields();
        }
      }
    },
    async setRelationshipFields() {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        {
          entity_id: this.getChildEntityDataById._id,
        }
      );
      if (this.getEntityRelationships && this.getEntityRelationships.length) {
        this.relatedFilterEntityRelationships = [...this.getEntityRelationships]
        let count = 0;
        let existedFilters = this.relationFilterEntityFields
          .filter((e) => e.entity_id)
          .flatMap((e) => e.entity_id);
        await Promise.all(
          this.relatedFilterEntityRelationships.map((rl) => {
            let isChild =
              rl.child_entity_id === this.getChildEntityDataById._id ? false : true;
            let rlEntityId = isChild ? rl.child_entity_id : rl.parent_entity_id;
            if (existedFilters.indexOf(rlEntityId) === -1) {
              this.relationFilterEntityFields.push({
                input_type: "RELATION",
                inputType: "RELATION",
                entity_id: rlEntityId,
                key: "self#related_to/" + (count + 1),
                label:
                  `With #` +
                  (isChild
                    ? rl.child_relation_title
                    : rl.parent_relation_title),
                section_name: "Relation",
                template_name: "Relation",
                data_type: "RELATION",
                validations: {
                  data_type: "RELATION",
                },
              });
              count++;
            }
          })
        );
      }
    },
    async checkEntityVariablesFields() {
      let entityVariables = (this.allFields || []).filter(
        (field) =>
          this.filterFields.indexOf(field.key) != -1 &&
          field.input_type == "ENTITY_VARIABLE"
      );
      if (entityVariables && entityVariables.length) {
        let params = {
          get_all: true,
          include_templates: entityVariables
            .filter((e) => e.global_variable_entity_field_template_id)
            .flatMap((e) => e.global_variable_entity_field_template_id),
        };
        this.filterLoading = true;
        await this.$store.dispatch(
          "companyTemplates/getAllCompanyTemplates",
          params
        );
        if (this.getAllCompanyTemplatesData?.data) {
          (this.getAllCompanyTemplatesData.data || []).forEach((template) => {
            if (template?.sections[0]?.fields) {
              (template?.sections[0]?.fields || []).forEach((field) => {
                if (
                  field?.input_type == "SELECT" ||
                  field?.input_type == "MULTI_SELECT"
                ) {
                  this.$set(
                    this.entityVariableSelectFields,
                    template._id + "#" + field.key,
                    field.options
                  );
                }
              });
            }
          });
        }
      }
      this.filterLoading = false;
    },
    clearFilters() {
      // console.log(this.filterFields, this.selectedFileds, "i need filter fields");
      this.filterFields.forEach((el) => {
        if (this.checkIsEntityFilter(el)) {
          this.selectedFileds[el] = ["ALL"];
        } else {
          this.selectedFileds[el] = [];
        }
      });
      this.$emit("quick-filter-updated", []);
    },
    getLabel(op, property) {
      if (typeof op == "object") {
        return property == "label" ? op.name : op.value ? op.value : op;
      }
      return op;
    },
    applyCustomDateFilter(value, field) {
      let filters = [];
      if (value) {
        // let dateValue = this.getDateByDays(0, value);
        let dateValue = moment(value).utc();
        filters.push({
          data_type: "DATE",
          field: field,
          operator: "real_time",
          selected_date_picker: "custom_date",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "OR",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: dateValue,
        });
        this.$emit("quick-filter-updated", filters);
      }
    },
    applyCustomDateRangeFilter(value, field) {
      let filters = [];
      if (value.length) {
        filters.push({
          data_type: "DATE",
          field: field,
          operator: "real_time",
          selected_date_picker: "custom_date_range",
          today: "",
          value_field: "",
          value_source: "",
          query_type: "OR",
          validations: {
            data_type: "",
          },
          value_type: "VALUE",
          every_year: "",
          entity_id: "",
          value: value,
        });
        this.$emit("quick-filter-updated", filters);
      }
    },
    filterUpdated(v) {
      if (!v) {
        this.dateFilter.custom_date = "";
        this.dateFilter.custom_date_range = [];
      }
      let filters = [];
      for (const property in this.selectedFileds) {
        if (
          this.selectedFileds[property] &&
          this.selectedFileds[property].length &&
          this.selectedFileds[property] !== "ALL" &&
          !this.selectedFileds[property].includes("ALL")
        ) {
          if (
            this.checkType(property) == "DATE" ||
            this.checkType(property) == "DATE_TIME"
          ) {
            let value = this.selectedFileds[property];
            if (value == "custom_date" || value == "custom_date_range") {
              if (
                (value == "custom_date" && !this.dateFilter.custom_date) ||
                (value == "custom_date_range" &&
                  !this.dateFilter.custom_date_range.length)
              ) {
                return;
              }
              filters.push({
                data_type: "DATE",
                field: property.split('#').length == 3 ? `${property.split('#')[0]}#${property.split('#')[1]}` : property,
                operator: "between",
                selected_date_picker: value,
                value:
                  value == "custom_date"
                    ? this.dateFilter.custom_date
                    : this.dateFilter.custom_date_range,
                today: "",
                value_field: "",
                value_source: "",
                query_type: "OR",
                validations: {
                  data_type: "",
                },
                value_type: "VALUE",
                every_year: "",
                entity_id: "",
                ignore : property.split('#').length == 3 ? true : false
              });
            } else {
              // let data = this.getDatePickerHelper1(value);
              filters.push({
                data_type: "DATE",
                field: property.split('#').length == 3 ? `${property.split('#')[0]}#${property.split('#')[1]}` : property,
                operator: "real_time",
                selected_date_picker: value,
                value: "",
                today: "",
                value_field: "",
                value_source: "",
                query_type: "OR",
                validations: {
                  data_type: "",
                },
                value_type: "VALUE",
                every_year: "",
                entity_id: "",
                ignore : property.split('#').length == 3 ? true : false
              });
            }
          } else {
            filters.push({
              field: property.split('#').length == 3 ? `${property.split('#')[0]}#${property.split('#')[1]}` : property,
              operator: "in",
              value: this.selectedFileds[property],
              value_type: "",
              value_source: "",
              data_type: "STRING",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              data_source: "field_option",
              ignore : property.split('#').length == 3 ? true : false
            });
          }
        }
      }
      this.$emit("quick-filter-updated", filters);
    },
    getSelectedEntityId(field) {
      if (field) {
        if (field.split("#").length == 3) {
          let selectedField = this.relationFilterEntityFields.find(
            (e) => e.key === `${field.split("#")[0]}#${field.split("#")[1]}`
          );
          if (selectedField && selectedField.entity_id) {
            return selectedField.entity_id;
          }
        } else {
          let selectedField = this.allFields.find((e) => e.key === field);
          if (selectedField && selectedField.entity_id) {
            return selectedField.entity_id;
          }
        }
        return "";
      }
      return "";
    },
    checkIsEntityFilter(field) {
      if (field) {
        if (field.split("#").length == 3) {
          let selectedField = this.relationFilterEntityFields.find(
            (e) => e.key === `${field.split("#")[0]}#${field.split("#")[1]}`
          );
          if (selectedField && selectedField.inputType === "ENTITY") {
            return true;
          }
        } else {
          let selectedField = this.allFields.find((e) => e.key === field);
          if (selectedField && selectedField.inputType === "ENTITY") {
            return true;
          }
        }
        return false;
      }
      return false;
    },

    checkType(field) {
      if (field.split("#").length == 3) {
        let selectedField = this.relationFilterEntityFields.find(
          (e) => e.key == `${field.split("#")[0]}#${field.split("#")[1]}`
        );
        return selectedField.inputType;
      } else {
        let selectedField = this.allFields.find((e) => e.key === field);
        return selectedField?.inputType;
      }
    },
    getFieldLabel(field) {
      if (field) {
        if (field.split("#").length == 3) {
          let selectedField = this.relationFilterEntityFields.find(
            (e) => e.key === `${field.split("#")[0]}#${field.split("#")[1]}`
          );
          if (selectedField && selectedField.label) {
            return `#${this.getChildEntityDataById.name} - ${selectedField.label}`;
          }
        } else {
          let selectedField = this.allFields.find((e) => e.key === field);
          if (selectedField && selectedField.label) {
            return selectedField.label;
          }
        }
        return "field";
      }
      return "field";
    },
    getFieldOptions(field) {
      if (field) {
        let selectedField;
        if(field.split('#').length == 3){
          selectedField = this.relationFilterEntityFields.find(fd => fd.key == `${field.split('#')[0]}#${field.split('#')[1]}`)
        }
        else{
          selectedField = this.allFields.find(fd => fd.key == field)
        }
        if (
          selectedField?.input_type == "ENTITY_VARIABLE" &&
          this.entityVariableSelectFields &&
          this.entityVariableSelectFields[
            selectedField.global_variable_entity_field
          ]
        ) {
          return this.entityVariableSelectFields[
            selectedField.global_variable_entity_field
          ];
        } else if (selectedField && selectedField.options) {
          return selectedField.options;
        }
        return [];
      }
      return [];
    },
  },
  props: [
    "filterFields",
    "allFields",
    "selectedFileds",
    "fromCD",
    "showVertically",
  ],
};
</script>
<style scoped lang="scss">
.collopse-header {
  color: #303133 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-left: 20px !important;
}
.date-picker-container {
  display: flex;
}
.date-filter-container {
  // display: flex;
  align-items: center;
}
</style>
